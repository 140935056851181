<template>
  <AuthWrapper>
    <div
      class="auth-contents"
      style="padding-top:150px"
    >
      <a-form
        :model="formState"
        layout="vertical"
        @finish="handleSubmit"
      >
        <sdHeading as="h3">
          Oturum <span class="color-secondary">Açınız</span>
        </sdHeading>
        <a-form-item
          name="username"
          label="Email"
        >
          <a-input
            v-model:value="formState.username"
            type="text"
          />
        </a-form-item>
        <a-form-item
          name="password"
          initial-value="123456"
          label="Şifre"
        >
          <a-input
            v-model:value="formState.password"
            type="password"
            placeholder="Password"
          />
        </a-form-item>
        <a-form-item>
          <sdButton
            class="btn-signin"
            html-type="submit"
            type="primary"
            size="large"
          >
            {{ isLoading ? 'Loading...' : 'Oturum Aç' }}
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { AuthWrapper } from './style'

export default {
  components: {
    AuthWrapper,
  },
  data() {
    return {
      formState: {
        username: '',
        password: '',
      },
    }
  },
  mounted() {

    if (this.$route.query.token) {

      this.$restMethods.postForOther('/check-token', {
        token: this.$route.query.token,
      }).then(response => {

        if ( ! response.hasError()) {

          const jwtContract = response.getData().jwt_contract

          localStorage.setItem('jwtToken', this.$route.query.token)
          localStorage.setItem('jwtContract', jwtContract)

          if (this.$route.query.orders && this.$route.query.orders.length > 0) {

            // query içindeki token'da gönderiliyor ama order sayfasında kullanmıyoruz.
            this.$router.replace({ name: 'O.Order.Page', query: this.$route.query })

          } else {

            if (jwtContract === '1') {

              this.$router.replace({ name: 'T.Index.Page' })

            } else {

              this.$router.replace({ name: 'C.Index.Page' })

            }

          }

        }

      })

    }

  },
  methods: {
    handleSubmit() {
      this.$message.error('Kullanıcı adı veya şifre hatalı')
    },
  },
}
</script>